import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col font-sans h-full text-white">
      <Header />
      <main className="flex flex-col flex-1 justify-center mx-auto px-6 w-full h-auto bg-gray-800">
        {children}
      </main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
