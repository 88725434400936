import React from "react";

function Header() {
  // const [isExpanded, toggleExpansion] = useState(false);
  // const { site } = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <header className="fixed bg-gray-800 w-full z-50">
      {/* <nav class="flex items-center flex-wrap p-6 justify-end">
        <a
          href="#responsive-header"
          class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white"
        >
          Blog
        </a>
      </nav> */}
    </header>
  );
}

export default Header;
